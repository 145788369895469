// ** React Imports
import { forwardRef, Fragment, useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Step from '@mui/material/Step'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stepper from '@mui/material/Stepper'
import StepLabel from '@mui/material/StepLabel'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

// ** Third Party Imports
import * as yup from 'yup'
import toast from 'react-hot-toast'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// ** Custom Components Imports
import StepperCustomDot from './StepperCustomDot'

// ** Styled Components
import StepperWrapper from 'src/@core/styles/mui/stepper'
import { Checkbox, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import FileUploaderMultiple from '../MulltipleFormUploader/FileUploaderMultiple'
import { useDispatch } from 'react-redux'
import { createPatient } from 'src/redux/patient/patient.action'
import VerifyEmailV1 from '../emailSendStepTwo/verifyEmail'
import DatePicker from 'react-datepicker'
import moment from 'moment'

const steps = [
  {
    title: 'Patient Basic Details',
    subtitle: 'Enter your Account Details'
  },
  {
    title: 'OTP Verfication & Other Stuff',
    subtitle: 'Setup Information'
  }
]

const defaultAccountValues = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  optionPhoneNumber: '',
  cinNumber: '',
  hasInsurance: 'yes',
  address: '',
  dob: null,
  scheduledDate: '',
  insuranceType: '',
  purposeOfVisit: [],
  files: [],
  status: '',
  comment: ''
}

const defaultPersonalValues = {}
const CustomInput = forwardRef(({ ...props }, ref) => {
  return <TextField inputRef={ref} {...props} sx={{ width: '100%' }} />
})

const accountSchema = yup.object().shape({
  lastName: yup.string().required(),
  firstName: yup.string().required(),
  email: yup.string().email().optional(),
  status: yup.string().optional(),
  comment: yup.string().optional(),
  phoneNumber: yup.string().required('Phone number is required'),
  optionPhoneNumber: yup.string().optional(),
  cinNumber: yup.string().required('CIN number is required'),
  hasInsurance: yup.string().required(),
  insuranceType: yup.string().when('hasInsurance', (hasInsurance, schema) => {
    return hasInsurance[0] === 'yes' ? schema.required('Insurance Type is required') : schema
  }),
  scheduledDate: yup.string().optional(),
  address: yup.string(),
  purposeOfVisit: yup.array().min(1, 'Select at least one purpose').required(),
  files: yup.array(),
  dob: yup
    .date()
    .required('Date of Birth is required')
    .test('is-valid-date', 'Invalid date', value => moment(value, 'MM-DD-YYYY', true).isValid())
    .test('is-past-date', 'Date of Birth must be in the past', value => moment().isAfter(moment(value)))
})

const StepperLinearWithValidation = () => {
  // ** States
  const [activeStep, setActiveStep] = useState(0)
  const dispatch = useDispatch()

  // ** Hooks
  const {
    reset: accountReset,
    control: accountControl,
    handleSubmit: handleAccountSubmit,
    watch: watchAccountFields,
    setValue: setAccountValue,
    clearErrors: clearAccountErrors,
    formState: { errors: accountErrors }
  } = useForm({
    defaultValues: defaultAccountValues,
    resolver: yupResolver(accountSchema)
  })

  const {
    reset: personalReset,
    control: personalControl,
    handleSubmit: handlePersonalSubmit,
    formState: { errors: personalErrors }
  } = useForm({
    defaultValues: defaultPersonalValues
    // resolver: yupResolver(personalSchema)
  })

  // Watch fields
  const hasInsurance = watchAccountFields('hasInsurance')
  const status = watchAccountFields('status')

  const handleFilesChange = files => {
    setAccountValue('files', files)
    clearAccountErrors('files')
  }

  // Handle Stepper
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
    accountReset({
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      optionPhoneNumber: '',
      status: '',
      comment: '',
      cinNumber: '',
      hasInsurance: 'yes',
      dob: null,
      scheduledDate: null,
      address: '',
      insuranceType: '',
      purposeOfVisit: [],
      files: []
    })
    personalReset({ country: '', language: [], 'last-name': '', 'first-name': '' })
  }

  // const onSubmit = () => {
  //   setActiveStep(activeStep + 1) // ** Source code imports
  //   if (activeStep === steps.length - 1) {
  //     toast.success('Form Submitted')
  //   }
  // }

  const onSubmit = async data => {
    if (activeStep === 0) {
      const formData = new FormData()

      Object.keys(data).forEach(key => {
        if (key === 'files' && data[key].length > 0) {
          data[key].forEach(file => formData.append(key, file))
        } else if (Array.isArray(data[key])) {
          data[key].forEach(item => formData.append(key, item))
        } else {
          formData.append(key, data[key])
        }
      })

      try {
        const response = await dispatch(createPatient(formData))
        localStorage.setItem('patientId', response?.payload)
        setActiveStep(activeStep + 1)
      } catch (error) {
        toast.error('Error submitting form')
      }
    } else {
      const patientId = localStorage.getItem('patientId')
      console.log(patientId, 'patientId')
      setActiveStep(activeStep + 1)
      if (activeStep === steps.length - 1) {
        toast.success('Step 2 Form Submitted')
      } else {
        setActiveStep(activeStep + 1)
      }
    }
  }

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <form key={0} onSubmit={handleAccountSubmit(onSubmit)}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Typography variant='body2' sx={{ fontWeight: 600, color: 'text.primary' }}>
                  {steps[0].title}
                </Typography>
                <Typography variant='caption' component='p'>
                  {steps[0].subtitle}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Controller
                    name='firstName'
                    control={accountControl}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        label='First Name'
                        onChange={onChange}
                        placeholder='carterLeonard'
                        error={Boolean(accountErrors.firstName)}
                        aria-describedby='stepper-linear-account-firstName'
                      />
                    )}
                  />
                  {accountErrors.firstName && (
                    <FormHelperText sx={{ color: 'error.main' }} id='stepper-linear-account-firstName'>
                      This field is required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Controller
                    name='lastName'
                    control={accountControl}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        label='Last Name'
                        onChange={onChange}
                        placeholder='Carter'
                        error={Boolean(accountErrors.lastName)}
                        aria-describedby='stepper-linear-account-lastName'
                      />
                    )}
                  />
                  {accountErrors.lastName && (
                    <FormHelperText sx={{ color: 'error.main' }} id='stepper-linear-account-lastName'>
                      This field is required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Controller
                    name='email'
                    control={accountControl}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        type='email'
                        value={value}
                        label='Email'
                        onChange={onChange}
                        error={Boolean(accountErrors.email)}
                        placeholder='carterleonard@gmail.com'
                        aria-describedby='stepper-linear-account-email'
                      />
                    )}
                  />
                  {accountErrors.email && (
                    <FormHelperText sx={{ color: 'error.main' }} id='stepper-linear-account-email'>
                      {accountErrors.email.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Controller
                    name='phoneNumber'
                    control={accountControl}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        type='text'
                        value={value}
                        label='Phone No.'
                        onChange={onChange}
                        placeholder='+11234568790'
                        error={Boolean(accountErrors.phoneNumber)}
                        aria-describedby='stepper-linear-account-phoneNumber'
                      />
                    )}
                  />
                  {accountErrors.phoneNumber && (
                    <FormHelperText sx={{ color: 'error.main' }} id='stepper-linear-account-phoneNumber'>
                      This field is required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Controller
                    name='optionPhoneNumber'
                    rules={{ required: false }}
                    control={accountControl}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        type='text'
                        value={value}
                        label='Optional Phone No.'
                        onChange={onChange}
                        placeholder='11234568790'
                        error={Boolean(accountErrors.optionPhoneNumber)}
                        aria-describedby='stepper-linear-account-optionPhoneNumber'
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name='dob'
                  control={accountControl}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      selected={value}
                      showYearDropdown
                      showMonthDropdown
                      onChange={e => onChange(e)}
                      placeholderText='MM/DD/YYYY'
                      customInput={
                        <CustomInput
                          value={value}
                          onChange={onChange}
                          label='Date of Birth'
                          error={Boolean(accountErrors.dob)}
                          aria-describedby='validation-basic-dob'
                        />
                      }
                    />
                  )}
                />
                {accountErrors.dob && (
                  <FormHelperText sx={{ mx: 3.5, color: 'error.main' }} id='validation-basic-dob'>
                    {accountErrors.dob.message}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Controller
                    name='address'
                    control={accountControl}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        label='Address'
                        onChange={onChange}
                        placeholder='Houston, USA'
                        error={Boolean(accountErrors.address)}
                        aria-describedby='stepper-linear-account-address'
                      />
                    )}
                  />
                  {accountErrors.address && (
                    <FormHelperText sx={{ color: 'error.main' }} id='stepper-linear-account-address'>
                      This field is required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Controller
                    name='cinNumber'
                    control={accountControl}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        type='text'
                        value={value}
                        label='CIN Number'
                        onChange={onChange}
                        placeholder='6721721367'
                        error={Boolean(accountErrors.cinNumber)}
                        aria-describedby='stepper-linear-account-cinNumber'
                      />
                    )}
                  />
                  {accountErrors.cinNumber && (
                    <FormHelperText sx={{ color: 'error.main' }} id='stepper-linear-account-cinNumber'>
                      This field is required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>Has Insurance?</FormLabel>
                  <Controller
                    name='hasInsurance'
                    control={accountControl}
                    render={({ field: { value, onChange } }) => (
                      <RadioGroup row value={value} onChange={onChange}>
                        <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                        <FormControlLabel value='no' control={<Radio />} label='No' />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>

              {hasInsurance === 'yes' && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Controller
                      name='insuranceType'
                      control={accountControl}
                      rules={{ required: true }}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          type='text'
                          value={value}
                          label='Insurance Type'
                          onChange={onChange}
                          error={Boolean(accountErrors.insuranceType)}
                          placeholder='e.g., Health, Dental, Vision'
                          aria-describedby='stepper-linear-account-insuranceType'
                        />
                      )}
                    />
                    {accountErrors.insuranceType && (
                      <FormHelperText sx={{ color: 'error.main' }} id='stepper-linear-account-insuranceType'>
                        {accountErrors.insuranceType.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>Status</FormLabel>
                  <Controller
                    name='status'
                    control={accountControl}
                    render={({ field: { value, onChange } }) => (
                      <RadioGroup row value={value} onChange={onChange}>
                        <FormControlLabel value='scheduled' control={<Radio />} label='Scheduled' />
                        <FormControlLabel value='no-answer' control={<Radio />} label='No Answer' />
                        <FormControlLabel value='wrong-number' control={<Radio />} label='Wrong Number' />
                        <FormControlLabel value='refused' control={<Radio />} label='Refused' />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>
              {status === 'scheduled' && (
                <Grid item xs={12} sm={6}>
                  <Controller
                    name='scheduledDate'
                    control={accountControl}
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        selected={value}
                        showYearDropdown
                        showMonthDropdown
                        onChange={e => onChange(e)}
                        placeholderText='MM/DD/YYYY'
                        customInput={
                          <CustomInput
                            value={value}
                            onChange={onChange}
                            label='Scheduled Date'
                            error={Boolean(accountErrors.scheduledDate)}
                            aria-describedby='validation-basic-scheduledDate'
                          />
                        }
                      />
                    )}
                  />
                  {accountErrors.scheduledDate && (
                    <FormHelperText sx={{ mx: 3.5, color: 'error.main' }} id='validation-basic-scheduledDate'>
                      {accountErrors.scheduledDate.message}
                    </FormHelperText>
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>Purpose of Visit</FormLabel>
                  <Controller
                    name='purposeOfVisit'
                    control={accountControl}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={value.includes('DOH')}
                              onChange={e => {
                                const newValue = e.target.checked ? [...value, 'DOH'] : value.filter(v => v !== 'DOH')
                                onChange(newValue)
                              }}
                            />
                          }
                          label='DOH'
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={value.includes('DOH & NYIA')}
                              onChange={e => {
                                const newValue = e.target.checked
                                  ? [...value, 'DOH & NYIA']
                                  : value.filter(v => v !== 'DOH & NYIA')
                                onChange(newValue)
                              }}
                            />
                          }
                          label='DOH & NYIA'
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={value.includes('NYIA')}
                              onChange={e => {
                                const newValue = e.target.checked ? [...value, 'NYIA'] : value.filter(v => v !== 'NYIA')
                                onChange(newValue)
                              }}
                            />
                          }
                          label='NYIA'
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={value.includes('M11Q')}
                              onChange={e => {
                                const newValue = e.target.checked ? [...value, 'M11Q'] : value.filter(v => v !== 'M11Q')
                                onChange(newValue)
                              }}
                            />
                          }
                          label='M11Q'
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={value.includes('DOH NYIA & M11Q')}
                              onChange={e => {
                                const newValue = e.target.checked
                                  ? [...value, 'DOH NYIA & M11Q']
                                  : value.filter(v => v !== 'DOH NYIA & M11Q')
                                onChange(newValue)
                              }}
                            />
                          }
                          label='DOH NYIA & M11Q'
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={value.includes('Refills')}
                              onChange={e => {
                                const newValue = e.target.checked
                                  ? [...value, 'Refills']
                                  : value.filter(v => v !== 'Refills')
                                onChange(newValue)
                              }}
                            />
                          }
                          label='PRIMARY CARE- (REFILLS)'
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={value.includes('PRE EMPLOYMENT FORM')}
                              onChange={e => {
                                const newValue = e.target.checked
                                  ? [...value, 'PRE EMPLOYMENT FORM']
                                  : value.filter(v => v !== 'PRE EMPLOYMENT FORM')
                                onChange(newValue)
                              }}
                            />
                          }
                          label='PRE EMPLOYMENT FORM'
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={value.includes('URGENT CARE- ( SICK VISIT)')}
                              onChange={e => {
                                const newValue = e.target.checked
                                  ? [...value, 'URGENT CARE- ( SICK VISIT)']
                                  : value.filter(v => v !== 'URGENT CARE- ( SICK VISIT)')
                                onChange(newValue)
                              }}
                            />
                          }
                          label='URGENT CARE- ( SICK VISIT)'
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={value.includes('DME SUPPLY REQUEST')}
                              onChange={e => {
                                const newValue = e.target.checked
                                  ? [...value, 'DME SUPPLY REQUEST']
                                  : value.filter(v => v !== 'DME SUPPLY REQUEST')
                                onChange(newValue)
                              }}
                            />
                          }
                          label='DME SUPPLY REQUEST'
                        />
                      </>
                    )}
                  />
                  {accountErrors.purposeOfVisit && (
                    <FormHelperText sx={{ color: 'error.main' }} id='stepper-linear-account-purposeOfVisit'>
                      {accountErrors.purposeOfVisit.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {/* <FileUploaderMultiple /> */}
                <FileUploaderMultiple onFilesChange={handleFilesChange} clearErrors={clearAccountErrors} />
                {accountErrors.files && (
                  <FormHelperText sx={{ color: 'error.main' }}>{accountErrors.files.message}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    name='comment'
                    control={accountControl}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        type='text'
                        value={value}
                        multiline
                        rows={3}
                        label='Additional Comment'
                        onChange={onChange}
                        placeholder='Additional Comment'
                        error={Boolean(accountErrors.comment)}
                        aria-describedby='stepper-linear-account-comment'
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button size='large' variant='outlined' color='secondary' disabled>
                  Back
                </Button>
                <Button size='large' type='submit' variant='contained'>
                  Next
                </Button>
              </Grid>
            </Grid>
          </form>
        )
      case 1:
        return (
          <form key={1} onSubmit={handlePersonalSubmit(onSubmit)}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Typography variant='body2' sx={{ fontWeight: 600, color: 'text.primary' }}>
                  {steps[1].title}
                </Typography>
                <Typography variant='caption' component='p'>
                  {steps[1].subtitle}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <VerifyEmailV1 />
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button size='large' variant='outlined' color='secondary' onClick={handleBack}>
                  Back
                </Button>
                <Button size='large' type='submit' variant='contained'>
                  Next
                </Button>
              </Grid>
            </Grid>
          </form>
        )
      default:
        return null
    }
  }

  const renderContent = () => {
    if (activeStep === steps.length) {
      return (
        <Fragment>
          <Typography>All steps are completed!</Typography>
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
            <Button size='large' variant='contained' onClick={handleReset}>
              Reset
            </Button>
          </Box>
        </Fragment>
      )
    } else {
      return getStepContent(activeStep)
    }
  }

  return (
    <Card>
      <CardContent>
        <StepperWrapper>
          <Stepper activeStep={activeStep}>
            {steps.map((step, index) => {
              const labelProps = {}
              if (index === activeStep) {
                labelProps.error = false
                if (
                  (accountErrors.email ||
                    accountErrors.username ||
                    accountErrors.password ||
                    accountErrors['confirm-password']) &&
                  activeStep === 0
                ) {
                  labelProps.error = true
                } else if (
                  (personalErrors.country ||
                    personalErrors.language ||
                    personalErrors['last-name'] ||
                    personalErrors['first-name']) &&
                  activeStep === 1
                ) {
                  labelProps.error = true
                } else {
                  labelProps.error = false
                }
              }

              return (
                <Step key={index}>
                  <StepLabel {...labelProps} StepIconComponent={StepperCustomDot}>
                    <div className='step-label'>
                      <Typography className='step-number'>{`0${index + 1}`}</Typography>
                      <div>
                        <Typography className='step-title'>{step.title}</Typography>
                        <Typography className='step-subtitle'>{step.subtitle}</Typography>
                      </div>
                    </div>
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </StepperWrapper>
      </CardContent>

      <Divider sx={{ m: '0 !important' }} />

      <CardContent>{renderContent()}</CardContent>
    </Card>
  )
}

export default StepperLinearWithValidation
