// ** MUI Components
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { styled, useTheme } from '@mui/material/styles'

// ** Configs
import themeConfig from 'src/configs/themeConfig'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { sendSMSPatient } from 'src/redux/sendSMS/sendSms.action'
import { useEffect } from 'react'
import { getPatient } from 'src/redux/patient/patient.action'
import { CircularProgress } from '@mui/material'

// ** Styled Components
const LinkStyled = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  marginLeft: theme.spacing(1),
  color: theme.palette.primary.main
}))

const VerifyEmailV1 = () => {
  // ** Hook
  const theme = useTheme()
  const dispatch = useDispatch()
  const { patient, isPatientLoading, isPatientLoaded } = useSelector(state => state.patient)
  const patientId = localStorage.getItem('patientId')
  useEffect(() => {
    if (patientId) {
      dispatch(getPatient(patientId))
    }
  }, [patientId])

  const handleSendSMS = () => {
    const patientId = localStorage.getItem('patientId')

    if (patientId) {
      const payload = {
        message: `Please find the consent form attached below for your review and signature. https://form.jotform.com/233526815658061 Should you have any questions or require further clarification, feel free to contact us. Best regards, ${themeConfig.templateName}`,
        patientId: patientId
      }

      // Dispatch the action to send SMS
      dispatch(sendSMSPatient(payload))
    } else {
      console.error('Patient ID is not found in local storage.')
    }
  }

  return (
    <>
      {isPatientLoading && <CircularProgress />}
      {isPatientLoaded && (
        <Box className='content-center'>
          <Card>
            <CardContent sx={{ p: `${theme.spacing(8, 8, 7)} !important` }}>
              <Box sx={{ mb: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography
                  variant='h5'
                  sx={{
                    ml: 2,
                    lineHeight: 1,
                    fontWeight: 700,
                    letterSpacing: '-0.45px',
                    textTransform: 'lowercase',
                    fontSize: '1.75rem !important'
                  }}
                >
                  {themeConfig.templateName}
                </Typography>
              </Box>
              <Typography variant='h5' sx={{ mb: 1.5 }}>
                Patient: {patient?.firstName + ' ' + patient?.lastName}
              </Typography>
              <Typography variant='h5' sx={{ mb: 1.5 }}>
                Send SMS to Patient ✉️
              </Typography>
              <Typography sx={{ mb: 6, color: 'text.secondary' }}>
                SMS Sent to this Phone-Number: <strong>{patient?.phoneNumber}</strong> Please follow the link inside to
                continue.
              </Typography>
              <Button fullWidth variant='contained' sx={{ mb: 4 }} onClick={handleSendSMS}>
                Send It
              </Button>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography sx={{ mr: 1 }} variant='body2'>
                  Didn't get the SMS?
                </Typography>
                <LinkStyled href='/' onClick={e => e.preventDefault()}>
                  Resend
                </LinkStyled>
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  )
}

export default VerifyEmailV1
